<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
      <el-form-item>
        <el-input v-model="dataForm.key" placeholder="参数名" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <el-button v-if="isAuth('wechat:feedback:save')" type="primary" @click="addOrUpdateHandle()">新增</el-button>
        <el-button v-if="isAuth('wechat:feedback:delete')" type="danger" @click="deleteHandle()"
                   :disabled="dataListSelections.length <= 0">批量删除
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
        :data="dataList"
        border
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%;">
      <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50">
      </el-table-column>
      <el-table-column
          prop="id"
          header-align="center"
          align="center"
          label="">
      </el-table-column>
      <el-table-column
          prop="content"
          header-align="center"
          align="center"
          label="反馈内容">
      </el-table-column>
      <el-table-column
          prop="contact"
          header-align="center"
          align="center"
          label="联系方式">
      </el-table-column>
      <el-table-column
          prop="updateTime"
          header-align="center"
          align="center"
          label="">
        <template slot-scope="scope">
          <span>{{ $utils.dateFormat('yyyy-MM-dd hh:mm', scope.row.updateTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="isDeal"
          header-align="center"
          align="center"
          label="是否处理">
      </el-table-column>
      <el-table-column
          prop="person"
          header-align="center"
          align="center"
          label="分配处理的人">
      </el-table-column>
      <el-table-column
          fixed="right"
          header-align="center"
          align="center"
          width="150"
          label="操作">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
          <el-button type="text" size="small" @click="deleteHandle(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper">
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
  </div>
</template>

<script>
import AddOrUpdate from './feedback-add-or-update'
import PageMixin from "@/mixins/Page";

export default {
  data() {
    return {
      dataForm: {
        key: ''
      },
    }
  },
  mixins: [PageMixin],
  components: {
    AddOrUpdate
  },
  activated() {
    this.getDataList()
  },
  methods: {
    // 获取数据列表
    getDataList() {
      this.doGetList('/wechat/feedback/list', {
        'page': this.pageIndex,
        'limit': this.pageSize,
        'key': this.dataForm.key
      })
    },
    // 删除
    deleteHandle(id) {
      this.doDelete('/wechat/feedback/delete', id)
    }
  }
}
</script>
