<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : '修改'"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" @keyup.enter.native="dataFormSubmit()"
             label-width="80px">
      <el-form-item label="反馈内容" prop="content">
        <el-input v-model="dataForm.content" placeholder="反馈内容"></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="contact">
        <el-input v-model="dataForm.contact" placeholder="联系方式"></el-input>
      </el-form-item>
      <el-form-item label="" prop="updateTime">
        <el-input v-model="dataForm.updateTime" placeholder=""></el-input>
      </el-form-item>
      <el-form-item label="是否处理" prop="isDeal">
        <el-input v-model="dataForm.isDeal" placeholder="是否处理"></el-input>
      </el-form-item>
      <el-form-item label="分配处理的人" prop="person">
        <el-input v-model="dataForm.person" placeholder="分配处理的人"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import AddOrUpdateMixin from '@/mixins/AddOrUpdate'

export default {
  mixins: [AddOrUpdateMixin],
  data() {
    return {
      dataRule: {
        content: [
          {required: true, message: '反馈内容不能为空', trigger: 'blur'}
        ],
        contact: [
          {required: true, message: '联系方式不能为空', trigger: 'blur'}
        ],
        isDeal: [
          {required: true, message: '是否处理不能为空', trigger: 'blur'}
        ],
        person: [
          {required: true, message: '分配处理的人不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init(id) {
      this.doInit(id, `/wechat/feedback/info`, 'feedback').then((resp)=> {
        this.dataForm.updateTime = this.$utils.dateFormat('yyyy-MM-dd hh:mm', resp.updateTime)
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.doSubmit(`/wechat/feedback/${!this.dataForm.id ? 'save' : 'update'}`, {
        'id': this.dataForm.id || undefined,
        'content': this.dataForm.content,
        'contact': this.dataForm.contact,
        'isDeal': this.dataForm.isDeal,
        'person': this.dataForm.person
      })
    }
  }
}
</script>
