//mixins/AddOrUpdate.js
const defaultRefName = 'dataForm'
export default {
    data() {
        return {
            visible: false,
            dataForm: {}
        }
    },
    methods: {
        doPromiseInit(id, url, params, refName) {
            refName = refName || defaultRefName
            this.dataForm.id = id || 0
            this.visible = true
            const that = this;
            return new Promise((resolve, reject) => {
                that.$nextTick(() => {
                    that.$refs[refName].resetFields()
                    if (that.dataForm.id) {
                        that.$http({
                            url: that.$http.adornUrl(`${url}/${that.dataForm.id}`),
                            method: 'get',
                            params: that.$http.adornParams()
                        }).then(({data}) => {
                            resolve(data)
                        }).catch(e => {
                            reject(e)
                        })
                    } else {
                        reject()
                    }
                })
            })
        },
        doInit(id, url, dataKey, params, refName) {
            const that = this;
            return new Promise((resolve, reject) => {
                that.doPromiseInit(id, url, params, refName).then((data) => {
                    if (data && data.code === 0) {
                        if (dataKey) {
                            data = data[dataKey]
                        }
                        if(data) {
                            that.$set(that, 'dataForm', data);
                        }
                        resolve(data)
                    } else {
                        reject(data.msg);
                    }
                }).catch(e => {
                    reject(e)
                })
            })
        },
        doSubmit(url, data, refName, emitEvent) {
            refName = refName || defaultRefName
            emitEvent = emitEvent || 'refreshDataList'
            const that = this;
            return new Promise((resolve, reject) => {
                that.$refs[refName].validate((valid) => {
                    if (valid) {
                        that.$http({
                            url: that.$http.adornUrl(url),
                            method: 'post',
                            data: that.$http.adornData(data)
                        }).then(({data}) => {
                            if (data && data.code === 0) {
                                that.$message({
                                    message: '操作成功',
                                    type: 'success',
                                    duration: 1500,
                                    onClose: () => {
                                        that.visible = false
                                        that.$emit(emitEvent)
                                    }
                                })
                                resolve(data)
                            } else {
                                that.$message.error(data.msg)
                                reject(data.msg)
                            }
                        })
                    }
                })
            })
        }
    }
}